import React from 'react'
import { Outlet } from 'react-router-dom'
import { createAmplifyListener } from '@meprism/shared/src/redux/authentication/createAmplifyListener'
import { usePageTracking } from '../../utils/hooks/usePageTracking'
import Container from '@mui/material/Container'
import { ToastContainer } from 'react-toastify'

// required to style the toast
import 'react-toastify/dist/ReactToastify.css'
import { store } from '../../redux/store'
import { useMomentSciencePixel } from '../../utils/hooks/useMomentSciencePixel'

export const rootLayoutLoader = () => {
  store.dispatch(
    createAmplifyListener({
      onAutoSignInFailure: () => {
        window.location.href = `${window.location.origin}/login`
      },
    }),
  )
  return null
}

export const RootLayout = () => {
  usePageTracking()
  useMomentSciencePixel()

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '100vh',
        flex: 1,
        px: 0,
      }}
      disableGutters
      maxWidth={false}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Outlet />
      {/* <MpFooter /> */}
    </Container>
  )
}
