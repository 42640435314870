import { buildAwsEndpoints } from '@meprism/shared/src/config/aws_config'
import { Amplify, API, Hub } from 'aws-amplify'

const endpoints = buildAwsEndpoints(process.env.REACT_APP_API_FQDN as string)

export const awsConfig = {
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: `${process.env.REACT_APP_OAUTH_REDIRECT}/oauth2/idpresponse`,
    redirectSignOut: `${process.env.REACT_APP_OAUTH_REDIRECT}/oauth2/idpresponse`,
    responseType: 'token',
  },
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_SRP_AUTH'
  },
  API: {
    endpoints,
  },
}

Amplify.configure(awsConfig)

export { API as configuredApi, Hub as configuredHub }
